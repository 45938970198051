<template>
  <div class="menu-facture-commercial">
    <div class="list-tabs">
      <v-tabs
        bg-color="#704ad1"
        :hide-slider="false"
        slider-color="#704ad1"
        v-model="model"
      >
        <v-tab>
          <router-link
            to="/commercial/appel-paiement"
            class="font-tabs-facture"
            v-if="checkPermission('GAPC')"
          >
            Appel à paiement
          </router-link>
        </v-tab>
        <!-- <v-tab>
          <router-link
            to="/commercial/facture-commercial"
            class="font-tabs-facture"
            v-if="checkPermission('FCTH')"
          >
            Factures commerciaux
          </router-link>
        </v-tab> -->
      </v-tabs>
      <div class="notif-export-file" v-if="getShowNotifExportFile">
        Préparation du fichier en cours
      </div>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      model: 0
    };
  },
  computed: {
    ...mapGetters(['checkPermission', 'getShowNotifExportFile'])
  }
};
</script>
<style scoped lang="scss">
.menu-facture-commercial {
  position: absolute;
  top: 35px;
  right: 0;
  left: 0px;
  height: 30px;
  // z-index: 999;
  background: #fff;
  border-top: outset;
  .list-tabs {
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    .v-tabs {
      width: max-content;
    }
    .v-application .deep-purple {
      background-color: #704ad1 !important;
      border-color: #704ad1 !important;
      width: 100%;
    }
  }
}
.v-tab {
  &:hover {
    text-decoration: none;
    background-color: #f1edfb;
  }
}
v-tab .v-tab--active {
  &:hover {
    text-decoration: none;
    background-color: #f1edfb;
  }
}
.v-application {
  a {
    color: #2f2f2f !important;
    font-size: 11px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
    // line-height: 42px;
    // padding: 9px 24px 9px 23px;
    text-align: center;
    text-transform: capitalize;
    &.router-link-exact-active {
      color: #704ad1 !important;
      font-size: 11px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      white-space: nowrap;
      // line-height: 42px;
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      text-decoration: none;
      background-color: #f1edfb;
    }
    &:focus {
    }
  }
}
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tab:first-child,
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 0px;
  padding: 0px;
}
</style>
<!-- <style lang="scss">
.v-tabs-bar {
  border-radius: inherit;
  height: 30px;
}
</style> -->
